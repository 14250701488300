import React from 'react';
import { Outlet } from 'react-router-dom';
import GlobalStyles from '@mui/joy/GlobalStyles';
import {
	Box,
	Button,
	formLabelClasses,
	IconButton,
	IconButtonProps,
	MenuItem,
	Stack,
	Typography,
	useColorScheme,
} from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserMenu from './Layout/UserMenu';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '~/hooks/auth';

/**
 * Layout for all conversion pages (login, register, forgot password, etc.)
 */
const ConversionLayout: React.FC = (props) => {
	const { authenticated } = useAuth();
	return (
		<>
			<GlobalStyles
				styles={{
					':root': {
						'--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
						'--Cover-width': '40vw', // must be `vw` only
						'--Form-maxWidth': '700px',
						'--Transition-duration': '0.4s', // set to `none` to disable transition,
						content:
							'url(https://source.unsplash.com/random/540x960/?mountains,clouds,day) ' +
							'url(https://source.unsplash.com/random/540x960/?mountains,clouds,night)',
					},
				}}
			/>
			<Box
				sx={(theme) => ({
					width: 'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
					transition: 'width var(--Transition-duration)',
					transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
					position: 'relative',
					zIndex: 1,
					display: 'flex',
					justifyContent: 'flex-end',
					backdropFilter: 'blur(4px)',
					backgroundColor: 'rgba(255 255 255 / 0.6)',
					[theme.getColorSchemeSelector('dark')]: {
						backgroundColor: 'rgba(19 19 24 / 0.4)',
					},
				})}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						minHeight: '100dvh',
						width: 'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
						maxWidth: '100%',
						px: 2,
					}}
				>
					<Box
						component="header"
						sx={{
							py: 3,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<img src="./assets/logo-light.svg" alt="" height="40" />
						<UserMenu visible={authenticated ?? false}>
							<MenuItem component={RouterLink} to="/logout">
								Logout
							</MenuItem>
							<MenuItem>
								<ColorSchemeToggle dataType="button" text />
							</MenuItem>
						</UserMenu>
					</Box>

					<Box
						component="main"
						sx={{
							my: 'auto',
							py: 2,
							pb: 5,
							display: 'flex',
							flexDirection: 'column',
							gap: 2,
							width: 450,
							mx: 'auto',
							maxWidth: '100%',
							borderRadius: 'sm',
							'& form': {
								display: 'flex',
								flexDirection: 'column',
								gap: 2,
							},
							[`& .${formLabelClasses.asterisk}`]: {
								visibility: 'hidden',
							},
						}}
					>
						<Outlet />
					</Box>
					<Box component="footer" sx={{ py: 3 }}>
						<Typography level="body-xs" textAlign="center">
							© Merlin Cloud {new Date().getFullYear()}
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box
				sx={(theme) => ({
					height: '100%',
					position: 'fixed',
					right: 0,
					top: 0,
					bottom: 0,
					left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
					transition:
						'background-image var(--Transition-duration), left var(--Transition-duration) !important',
					transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
					backgroundColor: 'background.level1',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					display: theme.palette.mode === 'dark' ? 'none' : 'block',
					backgroundImage:
						'url(https://source.unsplash.com/random/540x960/?mountains,clouds,day)',
				})}
			/>
			<Box
				sx={(theme) => ({
					height: '100%',
					position: 'fixed',
					right: 0,
					top: 0,
					bottom: 0,
					left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
					transition:
						'background-image var(--Transition-duration), left var(--Transition-duration) !important',
					transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
					backgroundColor: 'background.level1',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					display: theme.palette.mode === 'light' ? 'none' : 'block',
					backgroundImage:
						'url(https://source.unsplash.com/random/540x960/?mountains,clouds,night)',
				})}
			/>
			<Outlet />
		</>
	);
};

export default ConversionLayout;

export const ColorSchemeToggle = ({
	onClick,
	...props
}: IconButtonProps & { text?: boolean; dataType: 'button' | 'icon' }) => {
	const { mode, setMode } = useColorScheme();
	const [mounted, setMounted] = React.useState(false);
	React.useEffect(() => {
		setMounted(true);
	}, []);
	if (!mounted) {
		return <IconButton size="sm" variant="plain" color="neutral" disabled />;
	}

	const Toggle = (
		<>
			{props.text && (
				<Typography mr={1} level="body-md" textColor="text.primary" fontWeight={400}>
					{props.text ? 'Enable ' : ''}
					{mode === 'light' ? 'Dark' : 'Light'} Mode
				</Typography>
			)}

			{mode === 'light' ? (
				<FontAwesomeIcon icon={['fasr', 'moon']} />
			) : (
				<FontAwesomeIcon icon={['fasr', 'sun-bright']} />
			)}
		</>
	);

	if (props.dataType === 'button') {
		return (
			<IconButton
				sx={{
					'&:hover': {
						bgcolor: 'transparent',
					},
					display: 'flex',
					alignItems: 'center',
					gap: 1,
				}}
				id="toggle-mode"
				size="sm"
				variant="plain"
				color="neutral"
				aria-label="toggle light/dark mode"
				{...props}
				onClick={(event) => {
					if (mode === 'light') {
						setMode('dark');
					} else {
						setMode('light');
					}
					onClick?.(event);
				}}
			>
				{Toggle}
			</IconButton>
		);
	}

	return (
		<IconButton
			id="toggle-mode"
			size="sm"
			variant="plain"
			color="neutral"
			aria-label="toggle light/dark mode"
			{...props}
			onClick={(event) => {
				if (mode === 'light') {
					setMode('dark');
				} else {
					setMode('light');
				}
				onClick?.(event);
			}}
		>
			{Toggle}
		</IconButton>
	);
};
