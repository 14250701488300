import { useIntercom } from 'react-use-intercom';
import { useAuth } from '~/hooks/auth';

export function IntercomInitializer() {
	const { user, userLoading } = useAuth();
	const { boot, shutdown, hide, show, update } = useIntercom();

	if (!user?.firstName || !user?.email) return null;

	boot({
		// userHash: user._id,
		// userId: user._id,
		// email: user.email,
		name: user.firstName,
	});

	return null;
}
