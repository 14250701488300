import { Global } from '@emotion/react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { CssBaseline, useTheme } from '@mui/joy';
import { CssVarsProvider, StyledEngineProvider } from '@mui/joy/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import { Workbox } from 'workbox-window';
import { ErrorBoundary as _ErrorBoundary } from '~/components/ErrorBoundary';
import { rootRoutes } from '~/routes';
import { useRoutes } from '~/utils/contexts';

import { fasr } from '@fortawesome/sharp-regular-svg-icons';
import { fass } from '@fortawesome/sharp-solid-svg-icons';
import { enGB } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import { pdfjs } from 'react-pdf';
import { IntercomProvider } from 'react-use-intercom';
import { SidebarProvider } from '~/components/Layout/Sidebar';
import Loader from '~/components/Loader';
import RoutesProvider from '~/components/Routes';
import Toaster from '~/components/Toaster';
import { theme } from '~/theme/customTheme';
import AuthProvider from '~/utils/auth';
import { IntercomInitializer } from './components/IntercomProvider';
import globalStyles from './globalStyles';
registerLocale('en-GB', enGB);

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// @ts-ignore
library.add(fasr);
// @ts-ignore
library.add(fass);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			useErrorBoundary: (error: any) =>
				!(error?.response?.status >= 400 && error?.response?.status < 500),
			refetchOnWindowFocus: false,
			networkMode: process.env.NODE_ENV === 'development' ? 'always' : undefined,
		},
		mutations: {
			// Offline support
			networkMode: process.env.NODE_ENV === 'development' ? 'always' : undefined,
		},
	},
});

const App = () => {
	const theme = useTheme();
	const { router, routerRoutes } = useRoutes();

	// TODO: Use real precache from RDM in the future if it's an experience

	return (
		<Suspense fallback={<Loader />}>
			<Global styles={globalStyles(theme)} />
			{router}
		</Suspense>
	);
};

const Root = () => {
	return (
		<StyledEngineProvider injectFirst>
			<CssVarsProvider defaultMode="dark" disableTransitionOnChange theme={theme}>
				<CssBaseline />
				<ErrorBoundary
					FallbackComponent={_ErrorBoundary}
					onReset={() => {
						// reset the state of your app so the error doesn't happen again
					}}
				>
					<BrowserRouter>
						<QueryClientProvider client={queryClient}>
							<RoutesProvider routes={rootRoutes}>
								<AuthProvider>
									<IntercomProvider appId="m0rv704e">
										<IntercomInitializer />
										<SidebarProvider>
											<Toaster />
											<App />
										</SidebarProvider>
									</IntercomProvider>
								</AuthProvider>
							</RoutesProvider>
						</QueryClientProvider>
					</BrowserRouter>
				</ErrorBoundary>
			</CssVarsProvider>
		</StyledEngineProvider>
	);
};

// @ts-ignore
createRoot(document.getElementById('root')).render(<Root />);

if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
	const wb = new Workbox('/sw.js');

	wb.addEventListener('waiting', (event) => {
		console.log('WAITING');

		wb.addEventListener('controlling', () => {
			// At this point, reloading will ensure that the current
			// tab is loaded under the control of the new service worker.
			// Depending on your web app, you may want to auto-save or
			// persist transient state before triggering the reload.
			window.location.reload();
		});

		wb.messageSkipWaiting();

		window.addEventListener('beforeunload', async () => {
			await wb.messageSkipWaiting();
		});
	});

	wb.register();
}
