import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import Offline from '../pages/offline';
import { Box, Button, Container, Stack, Typography } from '@mui/joy';

export const ErrorBoundary: React.FC<FallbackProps & { error: any }> = ({
	error,
	resetErrorBoundary,
	...args
}) => {
	const status = error?.status;
	const data = error?.data;
	const errorName = error?.data?.errorName || error?.name;

	console.log({ error, args });
	if (status === 500) {
		return <Offline />;
	}

	if (status === 404) {
		return (
			<Container>
				<Typography level="h1">404</Typography>
				<Typography>Not found!</Typography>
				<Button onClick={() => (window.location.href = '/')}>Go home</Button>
			</Container>
		);
	}

	// return (
	// <Stack justifyContent="center" alignItems="center" height="100%" spacing={2}>
	// 	<Typography textAlign="center">{JSON.stringify(error?.stack)}</Typography>
	// </Stack>
	// );
	// the response json is automatically parsed to
	// `error.data`, you also have access to the status

	return (
		<Container>
			<Stack justifyContent="center" alignItems="center" height="100%" spacing={2}>
				<Typography level="title-lg">{status}</Typography>
				<Typography level="h2">{errorName}</Typography>
				<pre>
					<Box
						component="code"
						sx={{
							fontFamily: 'monospace',
							fontSize: 'inherit',
						}}
					>
						{JSON.stringify(error?.stack)}
					</Box>
				</pre>
				<Typography>An error occurred!</Typography>
				<Typography>
					Oh no! Something broke. Our team has been notified and we'll get on it!
				</Typography>
				<Button onClick={resetErrorBoundary}>Continue</Button>
			</Stack>
		</Container>
	);
};

export default ErrorBoundary;
